<template>
  <div>
    <v-dialog
      v-model='isEditDocShow'
      max-width='400'
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_document_setting') }}
        </v-card-title>
        <v-form
          ref='formUpdateDoc'
          @submit.prevent='updateSettingDoc'
        >
          <v-card-text>
            <v-text-field
              v-model='prefix'
              :label='labelMessage'
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model='amount'
              class='mt-3'
              :rules='[required]'
              :label="$t('amount')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model='code_run'
              class='mt-3'
              :ruless='[required]'
              :label="$t('code_run')"
              outlined
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              outlined
              @click='isEditDocShow = false'
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import { ref, watch } from '@vue/composition-api'
import document from '@/api/systemSetting/document'
import { required } from '@/@core/utils/validation'
import store from '@/store'
import { i18n } from '@/plugins/i18n'

export default {

  props: {

    documentSetting: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const formUpdateDoc = ref(null)
    const pathUpdateSettingDocs = ref('')
    const isEditDocShow = ref(false)
    const documentSettingLocal = ref('')
    const prefix = ref('')
    const code_run = ref('')
    const amount = ref('')
    const loading = ref(false)
    const labelMessage = ref(i18n.t('prefix'))
    const mapEdit = status => {
      if (status === 'customer') {
        pathUpdateSettingDocs.value = 'documentsetting/customer'
        prefix.value = documentSettingLocal.value.customer_id_default
        code_run.value = documentSettingLocal.value.customer_number_default
        amount.value = documentSettingLocal.value.customer_number_digit
      } else if (status === 'receipt') {
        pathUpdateSettingDocs.value = 'documentsetting/receipt'
        prefix.value = documentSettingLocal.value.receipt_id_default
        code_run.value = documentSettingLocal.value.receipt_number_default
        amount.value = documentSettingLocal.value.receipt_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'lot') {
        pathUpdateSettingDocs.value = 'documentsetting/druglot'
        prefix.value = documentSettingLocal.value.druglot_id_default
        code_run.value = documentSettingLocal.value.druglot_number_default
        amount.value = documentSettingLocal.value.druglot_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'transfer') {
        pathUpdateSettingDocs.value = 'documentsetting/transfer'
        prefix.value = documentSettingLocal.value.transfer_id_default
        code_run.value = documentSettingLocal.value.transfer_number_default
        amount.value = documentSettingLocal.value.transfer_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'opd') {
        pathUpdateSettingDocs.value = 'documentsetting/opd'
        prefix.value = documentSettingLocal.value.opd_id_default
        code_run.value = documentSettingLocal.value.opd_number_default
        amount.value = documentSettingLocal.value.opd_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'service') {
        pathUpdateSettingDocs.value = 'documentsetting/serve'
        prefix.value = documentSettingLocal.value.serve_id_default
        code_run.value = documentSettingLocal.value.serve_number_default
        amount.value = documentSettingLocal.value.serve_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'cert') {
        pathUpdateSettingDocs.value = 'documentsetting/cert'
        prefix.value = documentSettingLocal.value.cert_id_default
        code_run.value = documentSettingLocal.value.cert_number_default
        amount.value = documentSettingLocal.value.cert_number_digit
        labelMessage.value = i18n.t('endWording')
      } else if (status === 'sick') {
        pathUpdateSettingDocs.value = 'documentsetting/sick'
        prefix.value = documentSettingLocal.value.sick_id_default
        code_run.value = documentSettingLocal.value.sick_number_default
        amount.value = documentSettingLocal.value.sick_number_digit
        labelMessage.value = i18n.t('endWording')
      } else if (status === 'phrf') {
        pathUpdateSettingDocs.value = 'documentsetting/phrf'
        prefix.value = documentSettingLocal.value.phrf_id_default
        code_run.value = documentSettingLocal.value.phrf_number_default
        amount.value = documentSettingLocal.value.phrf_number_digit
        labelMessage.value = i18n.t('endWording')
      } else if (status === 'course') {
        pathUpdateSettingDocs.value = 'documentsetting/course'
        prefix.value = documentSettingLocal.value.course_id_default
        code_run.value = documentSettingLocal.value.course_number_default
        amount.value = documentSettingLocal.value.course_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'drug') {
        pathUpdateSettingDocs.value = 'documentsetting/drug'
        prefix.value = documentSettingLocal.value.drug_id_default
        code_run.value = documentSettingLocal.value.drug_number_default
        amount.value = documentSettingLocal.value.drug_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'lab') {
        pathUpdateSettingDocs.value = 'documentsetting/lab'
        prefix.value = documentSettingLocal.value.lab_id_default
        code_run.value = documentSettingLocal.value.lab_number_default
        amount.value = documentSettingLocal.value.lab_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'check') {
        pathUpdateSettingDocs.value = 'documentsetting/check'
        prefix.value = documentSettingLocal.value.check_id_default
        code_run.value = documentSettingLocal.value.check_number_default
        amount.value = documentSettingLocal.value.check_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'po') {
        pathUpdateSettingDocs.value = 'documentsetting/purchase'
        prefix.value = documentSettingLocal.value.purchase_id_default
        code_run.value = documentSettingLocal.value.purchase_number_default
        amount.value = documentSettingLocal.value.purchase_number_digit
        labelMessage.value = i18n.t('prefix')
      } else if (status === 'poOnline') {
        pathUpdateSettingDocs.value = 'documentsetting/purchaseonline'
        prefix.value = documentSettingLocal.value.purchaseonline_id_default
        code_run.value = documentSettingLocal.value.purchaseonline_number_default
        amount.value = documentSettingLocal.value.purchaseonline_number_digit
        labelMessage.value = i18n.t('prefix')
      }
      isEditDocShow.value = true
    }
    const { documentUpdate } = document
    const updateSettingDoc = () => {
      const isFormValid = formUpdateDoc.value.validate()
      if (!isFormValid) return
      loading.value = true
      let body = {}
      if (props.status === 'customer') {
        body = {
          customer_id_default: prefix.value,
          customer_number_default: code_run.value,
          customer_number_digit: amount.value,
        }
      } else if (props.status === 'receipt') {
        body = {
          receipt_id_default: prefix.value,
          receipt_number_digit: amount.value,
          receipt_number_default: code_run.value,
        }
      } else if (props.status === 'lot') {
        body = {
          druglot_id_default: prefix.value,
          druglot_number_digit: amount.value,
          druglot_number_default: code_run.value,
        }
      } else if (props.status === 'transfer') {
        body = {
          transfer_id_default: prefix.value,
          transfer_number_digit: amount.value,
          transfer_number_default: code_run.value,
        }
      } else if (props.status === 'opd') {
        body = {
          opd_id_default: prefix.value,
          opd_number_digit: amount.value,
          opd_number_default: code_run.value,
        }
      } else if (props.status === 'service') {
        body = {
          serve_id_default: prefix.value,
          serve_number_digit: amount.value,
          serve_number_default: code_run.value,
        }
      } else if (props.status === 'cert') {
        body = {
          cert_id_default: prefix.value,
          cert_number_digit: amount.value,
          cert_number_default: code_run.value,
        }
      } else if (props.status === 'sick') {
        body = {
          sick_id_default: prefix.value,
          sick_number_digit: amount.value,
          sick_number_default: code_run.value,
        }
      } else if (props.status === 'phrf') {
        body = {
          phrf_id_default: prefix.value,
          phrf_number_digit: amount.value,
          phrf_number_default: code_run.value,
        }
      } else if (props.status === 'course') {
        body = {
          course_id_default: prefix.value,
          course_number_digit: amount.value,
          course_number_default: code_run.value,
        }
      } else if (props.status === 'drug') {
        body = {
          drug_id_default: prefix.value,
          drug_number_digit: amount.value,
          drug_number_default: code_run.value,
        }
      } else if (props.status === 'lab') {
        body = {
          lab_id_default: prefix.value,
          lab_number_digit: amount.value,
          lab_number_default: code_run.value,
        }
      } else if (props.status === 'check') {
        body = {
          check_id_default: prefix.value,
          check_number_digit: amount.value,
          check_number_default: code_run.value,
        }
      } else if (props.status === 'po') {
        body = {
          purchase_id_default: prefix.value,
          purchase_number_digit: amount.value,
          purchase_number_default: code_run.value,
        }
      } else if (props.status === 'poOnline') {
        body = {
          purchaseonline_id_default: prefix.value,
          purchaseonline_number_digit: amount.value,
          purchaseonline_number_default: code_run.value,
        }
      }

      documentUpdate(pathUpdateSettingDocs.value, body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        isEditDocShow.value = false
        loading.value = false
        emit('onUpdate', res.response)
      })
    }

    watch(() => props.documentSetting, newValue => {
      documentSettingLocal.value = JSON.parse(JSON.stringify(newValue))
    })

    watch(() => isEditDocShow.value, newValue => {
      if (newValue === false) {
        emit('onChange', '')
      }
    })

    watch(() => props.status, newValue => {
      if (newValue) {
        mapEdit(newValue)
      }
    })

    return {
      updateSettingDoc,
      formUpdateDoc,
      prefix,
      required,
      amount,
      code_run,
      loading,
      isEditDocShow,
      labelMessage,
    }
  },
}
</script>
