<template>
  <div>
    <EditDocumentSetting
      :document-setting='documentSetting'
      :status='statusDoc'
      @onChange="statusDoc=''"
      @onUpdate='fetchDocs'
    />

    <AddNewSMSTopic
      v-model='isAddNewSms'
      @onAdd='fetchSmsList'
    />
    <EditSMSTopic
      v-model='isEditSms'
      :data-edit='dataEdit'
      @onUpdate='fetchSmsList'
    />

    <v-card :loading='loading'>
      <v-card-title>
        {{ $t('Document settings & SMS topics') }}
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th
                class='text-left'
              >
              </th>
              <th
                class='text-left'
              >
                {{ $t('prefix') }}
              </th>
              <th
                class='text-left'
              >
                {{ $t('code_run') }}
              </th>
              <th
                class='text-left'
              >
                {{ $t('digit_number') }}
              </th>
              <th
                class='text-left'
              >
                {{ $t('sample') }}
              </th>
              <th
                class='text-left'
              >
                {{ $t('option') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                {{ $t('customer') }}
              </td>
              <td>
                {{ documentSetting.customer_id_default }}
              </td>
              <td>
                {{ documentSetting.customer_number_default }}
              </td>
              <td>
                {{ documentSetting.customer_number_digit }}
              </td>
              <td>
                {{ documentSetting.customer }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc = 'customer'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('receipt') }}
              </td>
              <td>
                {{ documentSetting.receipt_id_default }}
              </td>
              <td>
                {{ documentSetting.receipt_number_default }}
              </td>
              <td>
                {{ documentSetting.receipt_number_digit }}
              </td>
              <td>
                {{ documentSetting.receipt }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='receipt'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('lot') }}
              </td>
              <td>
                {{ documentSetting.druglot_id_default }}
              </td>
              <td>
                {{ documentSetting.druglot_number_default }}
              </td>
              <td>
                {{ documentSetting.druglot_number_digit }}
              </td>
              <td>
                {{ documentSetting.druglot }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc='lot'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('transfer_out') }}
              </td>
              <td>
                {{ documentSetting.transfer_id_default }} ({{
                  $t('continue_with_the_shop_code')
                }})
              </td>
              <td>
                {{ documentSetting.transfer_number_default }}
              </td>
              <td>
                {{ documentSetting.transfer_number_digit }}
              </td>

              <td>
                {{ documentSetting.transfer }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='transfer'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                OPD
              </td>
              <td>
                {{ documentSetting.opd_id_default }}
              </td>
              <td>
                {{ documentSetting.opd_number_default }}
              </td>
              <td>
                {{ documentSetting.opd_number_digit }}
              </td>
              <td>
                {{ documentSetting.opd }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='opd'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('use_service') }}
              </td>
              <td>
                {{ documentSetting.serve_id_default }}
              </td>
              <td>
                {{ documentSetting.serve_number_default }}
              </td>
              <td>
                {{ documentSetting.serve_number_digit }}
              </td>
              <td>
                {{ documentSetting.serve }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc = 'service'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('medical_certificate') }}
              </td>
              <td>
                {{ documentSetting.cert_id_default }}
              </td>
              <td>
                {{ documentSetting.cert_number_default }}
              </td>
              <td>
                {{ documentSetting.cert_number_digit }}
              </td>
              <td>
                {{ documentSetting.cert }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc = 'cert'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('illness_certificate') }}
              </td>
              <td>
                {{ documentSetting.sick_id_default }}
              </td>
              <td>
                {{ documentSetting.sick_number_default }}
              </td>
              <td>
                {{ documentSetting.sick_number_digit }}
              </td>
              <td>
                {{ documentSetting.sick }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='sick'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('pharmacist_referral_form') }}
              </td>
              <td>
                {{ documentSetting.phrf_id_default }}
              </td>
              <td>
                {{ documentSetting.phrf_number_default }}
              </td>
              <td>
                {{ documentSetting.phrf_number_digit }}
              </td>
              <td>
                {{ documentSetting.phrf }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc = 'phrf'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('course') }}
              </td>
              <td>
                {{ documentSetting.course_id_default }}
              </td>
              <td>
                {{ documentSetting.course_number_default }}
              </td>
              <td>
                {{ documentSetting.course_number_digit }}
              </td>
              <td>
                {{ documentSetting.course }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='course'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('drugs_equipment') }}
              </td>
              <td>
                {{ documentSetting.drug_id_default }}
              </td>
              <td>
                {{ documentSetting.drug_number_default }}
              </td>
              <td>
                {{ documentSetting.drug_number_digit }}
              </td>
              <td>
                {{ documentSetting.drug }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc = 'drug'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('check_lab_sticker') }}
              </td>
              <td>{{ documentSetting.lab_id_default }}</td>
              <td>
                {{ documentSetting.lab_number_default }}
              </td>
              <td>
                {{ documentSetting.lab_number_digit }}
              </td>
              <td>
                {{ documentSetting.lab }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='lab'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('check') }}
              </td>
              <td>{{ documentSetting.check_id_default }}</td>
              <td>
                {{ documentSetting.check_number_default }}
              </td>
              <td>
                {{ documentSetting.check_number_digit }}
              </td>
              <td>
                {{ documentSetting.check }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='check'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                PO
              </td>
              <td>{{ documentSetting.purchase_id_default }}</td>
              <td>
                {{ documentSetting.purchase_number_default }}
              </td>
              <td>
                {{ documentSetting.purchase_number_digit }}
              </td>
              <td>
                {{ documentSetting.purchase }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='po'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td>
                PO Online
              </td>
              <td>{{ documentSetting.purchaseonline_id_default }}</td>
              <td>
                {{ documentSetting.purchaseonline_number_default }}
              </td>
              <td>
                {{ documentSetting.purchaseonline_number_digit }}
              </td>
              <td>
                {{ documentSetting.purchaseonline }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                      icon
                      color='primary'
                      v-bind='attrs'
                      @click="isEditDoc = true, statusDoc ='poOnline'"
                      v-on='on'
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('edit') }}</span>
                </v-tooltip>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card class='mt-3'>
      <v-card-title>
        <span>{{ $t('setting') }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class='py-2 d-flex align-start justify-space-around'>
        <v-radio-group v-model='documentSetting.cashback_id' dense @change='updateCashbackSetting'>
          <v-radio
            :label="$t('unknown')"
            value='0'
          ></v-radio>
          <v-radio
            :label="$t('pointFormat')"
            value='1'
          ></v-radio>
          <v-radio
            :label="$t('couponFormat')"
            value='2'
          ></v-radio>
          <template v-slot:label>
            <h4>
              {{ $t('Setting-Cashback') }}
            </h4>
          </template>
        </v-radio-group>
        <v-divider vertical class='mx-2'></v-divider>
        <v-radio-group v-model='documentSetting.print_id' dense @change='updatePrintSetting'>
          <v-radio
            value='1'
          >
            <template v-slot:label>
              {{ $t('black_and_white_printing') }}
            </template>
          </v-radio>
          <v-radio
            value='2'
          >
            <template v-slot:label>
              {{ $t('color_printing') }}
              <v-icon
                class='ma-2'
                color='primary'
              >
                mdi-image-search-outline
              </v-icon>
            </template>
          </v-radio>
          <template v-slot:label>
            <h4>
              {{ $t('receipt_color_setting') }}
            </h4>
          </template>
        </v-radio-group>
        <v-divider vertical class='mx-2'></v-divider>
        <v-radio-group v-model='documentSetting.commission_id' dense
                       @change='updateCommissionSetting'>
          <v-radio
            :label="$t('unable')"
            value='0'
          ></v-radio>
          <v-radio
            :label="$t('enable')"
            value='1'
          ></v-radio>
          <template v-slot:label>
            <h4>
              {{ $t('commission_setting') }}
            </h4>
          </template>
        </v-radio-group>
        <v-divider vertical class='mx-2'></v-divider>
        <v-radio-group v-model='documentSetting.vat_id' dense @change='updateVatSetting'>
          <v-radio
            v-for='(item, index) in vatList'
            :key='index'
            :label="$i18n.locale == 'th' ? item.th : item.en"
            :value='item.value'
          ></v-radio>
          <template v-slot:label>
            <h4>
              {{ $t('vat_setting') }}
            </h4>
          </template>
        </v-radio-group>
      </v-card-text>
    </v-card>

    <!--    <v-row class='mt-3'>-->
    <!--      <v-col-->
    <!--        cols='12'-->
    <!--        lg='3'-->
    <!--      >-->
    <!--        <v-card height='328'>-->
    <!--          <v-card-title>-->
    <!--            {{ $t('Setting-Cashback') }}-->
    <!--          </v-card-title>-->
    <!--          <v-card-text>-->
    <!--            <v-radio-group v-model='documentSetting.cashback_id'>-->
    <!--              <v-radio-->
    <!--                :label="$t('unknown')"-->
    <!--                value='0'-->
    <!--              ></v-radio>-->
    <!--              <v-radio-->
    <!--                :label="$t('pointFormat')"-->
    <!--                value='1'-->
    <!--              ></v-radio>-->
    <!--              <v-radio-->
    <!--                :label="$t('couponFormat')"-->
    <!--                value='2'-->
    <!--              ></v-radio>-->
    <!--            </v-radio-group>-->
    <!--          </v-card-text>-->
    <!--          <v-card-actions>-->
    <!--            <v-spacer></v-spacer>-->
    <!--            <v-btn-->
    <!--              color='primary'-->
    <!--              :loading='cashbackLoading'-->
    <!--              :disabled='cashbackLoading'-->
    <!--              @click='updateCashbackSetting'-->
    <!--            >-->
    <!--              {{ $t('save') }}-->
    <!--            </v-btn>-->
    <!--          </v-card-actions>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--      <v-col-->
    <!--        cols='12'-->
    <!--        lg='3'-->
    <!--      >-->
    <!--        <v-card height='328'>-->
    <!--          <v-card-title>-->
    <!--            {{ $t('receipt_color_setting') }}-->
    <!--          </v-card-title>-->
    <!--          <v-card-text>-->
    <!--            <v-radio-group v-model='documentSetting.print_id'>-->
    <!--              <v-radio-->
    <!--                value='1'-->
    <!--              >-->
    <!--                <template v-slot:label>-->
    <!--                  {{ $t('black_and_white_printing') }}-->
    <!--                </template>-->
    <!--              </v-radio>-->
    <!--              <v-radio-->
    <!--                value='2'-->
    <!--              >-->
    <!--                <template v-slot:label>-->
    <!--                  {{ $t('color_printing') }}-->
    <!--                  <v-icon-->
    <!--                    class='ma-2'-->
    <!--                    color='primary'-->
    <!--                  >-->
    <!--                    mdi-image-search-outline-->
    <!--                  </v-icon>-->
    <!--                </template>-->
    <!--              </v-radio>-->
    <!--            </v-radio-group>-->
    <!--          </v-card-text>-->
    <!--          <v-card-actions>-->
    <!--            <v-spacer></v-spacer>-->
    <!--            <v-btn-->
    <!--              color='primary'-->
    <!--              :loading='printLoading'-->
    <!--              :disabled='printLoading'-->
    <!--              @click='updatePrintSetting'-->
    <!--            >-->
    <!--              {{ $t('save') }}-->
    <!--            </v-btn>-->
    <!--          </v-card-actions>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--      <v-col-->
    <!--        cols='12'-->
    <!--        lg='3'-->
    <!--      >-->
    <!--        <v-card height='328'>-->
    <!--          <v-card-title>-->
    <!--            {{ $t('commission_setting') }}-->
    <!--          </v-card-title>-->
    <!--          <v-card-text>-->
    <!--            <v-radio-group v-model='documentSetting.commission_id'>-->
    <!--              <v-radio-->
    <!--                :label="$t('unable')"-->
    <!--                value='0'-->
    <!--              ></v-radio>-->
    <!--              <v-radio-->
    <!--                :label="$t('enable')"-->
    <!--                value='1'-->
    <!--              ></v-radio>-->
    <!--            </v-radio-group>-->
    <!--          </v-card-text>-->
    <!--          <v-card-actions>-->
    <!--            <v-spacer></v-spacer>-->
    <!--            <v-btn-->
    <!--              color='primary'-->
    <!--              :loading='commissionLoading'-->
    <!--              :disabled='commissionLoading'-->
    <!--              @click='updateCommissionSetting'-->
    <!--            >-->
    <!--              {{ $t('save') }}-->
    <!--            </v-btn>-->
    <!--          </v-card-actions>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--      <v-col-->
    <!--        cols='12'-->
    <!--        lg='3'-->
    <!--      >-->
    <!--        <v-card>-->
    <!--          <v-card-title>-->
    <!--            {{ $t('vat_setting') }}-->
    <!--          </v-card-title>-->
    <!--          <v-card-text>-->
    <!--            <v-radio-group v-model='documentSetting.vat_id'>-->
    <!--              <v-radio-->
    <!--                v-for='(item, index) in vatList'-->
    <!--                :key='index'-->
    <!--                :label="$i18n.locale == 'th' ? item.th : item.en"-->
    <!--                :value='item.value'-->
    <!--              ></v-radio>-->
    <!--            </v-radio-group>-->
    <!--          </v-card-text>-->
    <!--          <v-card-actions>-->
    <!--            <v-spacer></v-spacer>-->
    <!--            <v-btn-->
    <!--              color='primary'-->
    <!--              :loading='vatLoading'-->
    <!--              :disabled='vatLoading'-->
    <!--              @click='updateVatSetting'-->
    <!--            >-->
    <!--              {{ $t('save') }}-->
    <!--            </v-btn>-->
    <!--          </v-card-actions>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <v-card class='mt-6'>
      <v-card-title class='px-2'>
        {{ $t('sms_topic') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          class='d-none d-md-block'
          @click='isAddNewSms=true'
        >
          <v-icon class='me-1'>
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('add_sms_topic') }}
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          fab
          outlined
          icon
          @click='isAddNewSms=true'
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers='tableColumns'
        :items='smsList'
        :loading='smsListLoading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.headsms_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.actions`]='{ item}'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                v-bind='attrs'
                color='primary'
                @click='dataEdit = item, isEditSms = true'
                v-on='on'
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                @click='smsId = item.headsms_id, isDelete = true'
                v-on='on'
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model='isDelete'
      max-width='350'
      persistent
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class='me-1'
            color='warning'
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>
          {{ $t('confirm_sms_toppic_delete') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='error'
            :loading='processDelete'
            :disabled='processDelete'
            @click='deleteSms'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color='secondary'
            outlined
            @click='isDelete = false'
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiPencilOutline, mdiPlus, mdiDeleteOutline, mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useDocumentSetting from './useDocumentSetting'
import vatData from '@/@fake-db/data/vatData.json'
import EditDocumentSetting from './EditDocumentSetting.vue'
import AddNewSMSTopic from './AddNewSMSTopic.vue'
import EditSMSTopic from './EditSMSTopic.vue'

export default {

  components: {
    EditDocumentSetting,
    AddNewSMSTopic,
    EditSMSTopic,
  },

  setup() {
    const isEditDoc = ref(false)
    const statusDoc = ref('')
    const isAddNewSms = ref(false)
    const isEditSms = ref(false)
    const dataEdit = ref({})
    const {
      isDelete,
      smsId,
      printLoading,
      vatLoading,
      commissionLoading,
      cashbackLoading,
      message,
      colorStatus,
      processDelete,
      smsListLoading,
      statusShowAlert,
      documentSetting,
      smsList,
      tableColumns,
      loading,
      fetchDocs,
      updatePrintSetting,
      updateVatSetting,
      updateCashbackSetting,
      updateCommissionSetting,
      fetchSmsList,
      deleteSms,
    } = useDocumentSetting()
    const vatList = vatData.data

    return {
      processDelete,
      smsListLoading,
      isDelete,
      smsId,
      dataEdit,
      isEditSms,
      isAddNewSms,
      printLoading,
      vatLoading,
      commissionLoading,
      message,
      colorStatus,
      statusShowAlert,
      statusDoc,
      loading,
      isEditDoc,
      smsList,
      tableColumns,
      fetchDocs,
      vatList,
      cashbackLoading,
      documentSetting,
      updatePrintSetting,
      updateVatSetting,
      updateCashbackSetting,
      updateCommissionSetting,
      fetchSmsList,
      deleteSms,
      icons: {
        mdiDeleteOutline,
        mdiAlertOutline,
        mdiPencilOutline,
        mdiPlus,
      },
    }
  },

}
</script>
