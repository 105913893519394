<template>
  <div>
    <v-dialog
      v-model="isAddNewSms"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_sms_topic') }}
        </v-card-title>
        <v-form
          ref="formAddSms"
          @submit.prevent="createNewTopic"
        >
          <v-card-text>
            <v-text-field
              v-model="topic_name"
              autofocus
              :rules="[required]"
              outlined
              :label="$t('sms_topic')"
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-sms', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import document from '@/api/systemSetting/document'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewSms',
    event: 'update:is-add-new-sms',
  },
  props: {
    isAddNewSms: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const formAddSms = ref(null)

    const loading = ref(false)
    const topic_name = ref('')
    const { addSmsTopic } = document
    const createNewTopic = () => {
      const isFormValid = formAddSms.value.validate()
      if (!isFormValid) return
      loading.value = true
      const body = {
        headsms_name: topic_name.value,
      }
      addSmsTopic(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-add-new-sms', false)
        formAddSms.value.reset()
        emit('onAdd', res.response)
      })
    }
    watch(() => props.isAddNewSms, newValue => {
      if (newValue) {
        topic_name.value = ''
      }
    })

    return {
      topic_name,
      formAddSms,
      createNewTopic,
      required,

      loading,
    }
  },
}
</script>
