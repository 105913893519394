import { onMounted, ref } from '@vue/composition-api'
import document from '@/api/systemSetting/document'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default function useDocumentSetting() {
  const isDelete = ref(false)
  const smsId = ref('')
  const documentSetting = ref({})
  const loading = ref(true)
  const printLoading = ref(false)
  const cashbackLoading = ref(false)
  const commissionLoading = ref(false)
  const vatLoading = ref(false)
  const smsList = ref([])
  const smsListLoading = ref(false)
  const processDelete = ref(false)
  const tableColumns = [
    { text: '#', align: 'start', value: 'headsms_id' },
    { text: i18n.t('sms_topic'), value: 'headsms_name', width: 500 },
    { text: i18n.t('option'), value: 'actions', align: 'center' },
  ]
  const {
    documentSetting: getDocs,
    smsList: getSmsList,
    printSetting,
    commissionSetting,
    vatSetting,
    deleteSmsTopic,
    cashbackSetting,
  } = document

  onMounted(() => {
    fetchDocs()
    fetchSmsList()
  })

  const updateCashbackSetting = () => {
    cashbackLoading.value = true
    const body = {
      cashback_id: documentSetting.value.cashback_id,
    }
    cashbackSetting(body).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      cashbackLoading.value = false
      fetchDocs()
    })
  }

  const updatePrintSetting = () => {
    printLoading.value = true
    const body = {
      print_id: documentSetting.value.print_id,
    }
    printSetting(body).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      printLoading.value = false
      fetchDocs()
    })
  }
  const updateVatSetting = () => {
    vatLoading.value = true
    const body = {
      vat_id: documentSetting.value.vat_id,
    }
    vatSetting(body).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      vatLoading.value = false
      fetchDocs()
    })
  }
  const updateCommissionSetting = () => {
    commissionLoading.value = true
    const body = {
      commission_id: documentSetting.value.commission_id,
    }
    commissionSetting(body).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      commissionLoading.value = false
      fetchDocs()
    })
  }
  const fetchDocs = () => {
    getDocs().then(res => {
      res.vat_id = +res.vat_id
      documentSetting.value = res
      loading.value = false
    })
  }
  const fetchSmsList = () => {
    smsListLoading.value = true
    getSmsList().then(res => {
      smsList.value = res
      smsListLoading.value = false
    })
  }
  const deleteSms = () => {
    processDelete.value = true
    deleteSmsTopic(smsId.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      processDelete.value = false
      fetchSmsList()
      isDelete.value = false
    })
  }

  return {
    smsListLoading,
    processDelete,
    isDelete,
    smsId,
    printLoading,
    vatLoading,
    commissionLoading,
    documentSetting,
    smsList,
    tableColumns,
    cashbackLoading,
    loading,
    updateCashbackSetting,
    deleteSms,
    fetchDocs,
    fetchSmsList,
    updatePrintSetting,
    updateVatSetting,
    updateCommissionSetting,
  }
}
